.App {
   background-color: #282c34;
   min-height: 100vh;
   color: #ddd;
   font-size: calc(10px + 2vmin);
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

.large-screen {
   display: inherit;
}

.medium-screen {
   display: none;
}

.small-screen {
   display: none;
}

@media screen and (max-width: 666px) {
   .large-screen {
      display: none;
   }

   .medium-screen {
      display: inherit;
   }

   .small-screen {
      display: none;
   }
}

@media screen and (max-width: 440px) {
   .large-screen {
      display: none;
   }

   .medium-screen {
      display: none;
   }

   .small-screen {
      display: inherit;
   }
}
