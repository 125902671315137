.TopBar {
   background-color: black;
   color: white;
   display: flex;
   justify-content: space-between;
   padding: 3px;
   .UserSection {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .UserName {
         color: #bbb;
         flex: 0 0 100%;
         text-align: right;
         padding-right: 5px;
      }
      .Logout {
         flex-grow: 0;
         font-size: 0.5em;
         cursor: pointer;
         color: #999;
         background: none;
         border: none;
         padding-right: 5px;
      }
   }
}
