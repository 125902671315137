.UnauthenticatedHome {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100vh;
   justify-content: center;

   .Logo {
      font-size: 3em;
      text-align: center;
   }

   .Subtitle {
      color: #999;
      padding-bottom: 15px;
   }

   .Login{
      cursor: pointer;
   }
}
