.PlayListView {
   li.Done {
      text-decoration: line-through;
   }

   table.PlayListViewTable {
      table-layout: fixed;
      width: 100%;
      font-size: 0.6em;
      .NameColumn{
         width: 30%
      }

      th {
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
      }

      td{
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;

         &.LoadScoreButton {
            color: white;
            text-decoration: none;
            padding: 5px 5px;
            border: 1px solid white;
            border-radius: 3px;
            font-size: 0.75em;
            white-space: nowrap;
            cursor: pointer;
            text-align: center;
            user-select: none;
   
            &:hover {
               color: black;
               background-color: white;
            }
         }
      }
   }
}
