.AuthenticatedApp {
   .AppContent {
      padding: 10px;
      nav {
         ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0 20px;
            padding: 5px;
            list-style-type: none;
            justify-content: space-between;

            li {
               display: block;
               padding-left: 5px;
               padding-right: 5px;
               a {
                  display: block;
                  color: white;
                  text-decoration: none;
                  padding: 5px 15px;
                  border: 1px solid white;
                  border-radius: 3px;
                  font-size: 0.75em;
                  user-select: none;

                  &:hover {
                     color: black;
                     background-color: white;
                  }
               }
            }
         }
      }
   }
}
