.PlayList {
   li.Done {
      text-decoration: line-through;
   }
   nav.PlayListNav{
      ul{
         justify-content: center;
      }
   }
}
